import { Button, Title, Stack, Group, rem } from "@mantine/core";
import Icon from "./Icon";
import React from "react";

export default function Row({ rowId, row }) {
    return (
        <Stack align="center" justify="flex-start" gap="0" w="100%" py="5">
            <Group justify="space-between" gap="5" w="100%" grow>
                {row?.links?.map((link, i) => {
                    if(link.text)
                        return <Button key={rowId + "-Link-" + i} variant="default" size="lg" radius="md" leftSection={link.leftIcon && <Icon style={{width: rem(25), height: rem(25)}} name={link.leftIcon} /> } rightSection={link.rightIcon && <Icon style={{width: rem(25), height: rem(25)}} name={link.rightIcon} /> } fullWidth={true} w="100%" component="a" href={link.link} target={link.target}>{link.text}</Button>
                    else
                        return <Button key={rowId + "-Link-" + i} variant="default" size="lg" radius="md" fullWidth={true} w="100%" component="a" href={link.link} target={link.target}>
                            {link.leftIcon && <Icon style={{width: rem(25), height: rem(25)}} name={link.leftIcon} /> }
                            {link.rightIcon && <Icon style={{width: rem(25), height: rem(25)}} name={link.rightIcon} /> }
                        </Button>
                })}
            </Group>
        </Stack>
    )
}
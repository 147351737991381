import { Image, Title, Stack, rem } from "@mantine/core";
import React from "react";
import Row from "./Row";

export default function Page({ data }) { //TODO: create Page Object
  return (
    data?.content?.map((e, i) => {
      return (
        <Stack key={'Stack-' + i} align="center" justify="flex-start" gap="0" py="xs" w="100%">
          {e.image && <Image src={e.image} w="20vw" pb={e.title || e.subtitle ? "0" : rem(15)}/>}
          {e.title && <Title order={1} py={e.subtitle ? "1" : "5"}>{e.title}</Title>}
          {e.subtitle && <Title order={4} pb={rem(10)}>{e.subtitle}</Title>}
          {e.rows?.map((row, rowIndex) => {
            return <Row key={'Stack-' + i + '-Rows-' + rowIndex} rowId={'Stack-' + i + '-Rows-' + rowIndex} row={row}/>
          })}
        </Stack>
      )
    })
  )
}
import React from "react";
import { MantineProvider, AppShell, Button, Group, createTheme, Center, Image, Stack, Text, rem, Space } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import ErrorNotFound from './ErrorNotFound';
import {
  Route,
  Routes,
  NavLink
} from "react-router-dom";
import Icon from "./Icon";
import Page from './Page';
import data from './data.json';
import ScrollToTop from "./ScrollToTop";


  const theme = createTheme({
    //primaryColor: 'ocean-blue',
    colors: {
      'ocean-blue': ['#7AD1DD', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#09ADC3', '#0E99AC', '#128797', '#147885'],
    }
  });

export default function App() {
  return (
    <MantineProvider theme={theme}>
      <ScrollToTop />
      <AppShell
        header={{ height: data?.header?.visible ? 70 : 5 }}
        footer={{ height: data?.pages?.length > 1 ? 80 : 5 }}
        padding="md"
      >
        {data?.header?.visible &&
          <AppShell.Header pos={Center}>
            <Center h="100%" w="100%">
              <Button component="a" href={data?.header?.logo?.link} variant="transparent" p="0">
                <Center h="100%" w="100%">
                  <Image src={data?.header?.logo?.image} fit="cover" h="100%" w="100%" />
                </Center>
              </Button>
            </Center>
          </AppShell.Header>
        }
        <AppShell.Main>
          <Stack align="center" pb="sm">
            <Routes>
              {data?.pages?.map((e, i) => <Route key={i} path={e.route} element={<Page data={e} />} />)}
              <Route path="*" element={<ErrorNotFound />} />
            </Routes>
          </Stack>
        </AppShell.Main>
        {data?.pages?.length > 1 &&
          <AppShell.Footer pt="0" px="0" className="safe-bottom">
            <Group justify="space-between" gap="xs" h="100%" grow py="xs">
              {data?.pages?.map((e, i) =>
                <NavLink key={i} to={e.route} w="100%" h="100%">
                  {({ isActive, isPending, isTransitioning }) =>
                    <Button variant="subtle" color={isActive ? null : "gray"} size="sm" w="100%" h="100%">
                      <Stack
                        align="center"
                        gap="0"
                      >
                        <Icon name={e.icon} style={{ width: rem(28), height: rem(28) }} stroke={isActive ? 2 : 1} />
                        <Text size={rem(15)} pb="xs" fw={isActive ? 600 : 400}>{e.name}</Text>
                      </Stack>
                    </Button>
                  }
                </NavLink>
              )}
            </Group>
          </AppShell.Footer>
        }
      </AppShell>
    </MantineProvider>
  )
}